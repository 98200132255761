<template>
  <transition
    enter-active-class="duration-500 ease-in"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-500 ease-out"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div v-if="loading" class="transition flex items-center justify-center" style="height: 100px">
      <div :class="['loading-icon border-4 border-blue-500', { invisible: !loading }]"></div>
    </div>
  </transition>
</template>

<script>
export default {
  mounted () {
    document.addEventListener('touchstart', this.touchstart, false)
    document.addEventListener('touchmove', this.touchmove, false)
    document.addEventListener('touchend', this.touchend, false)
  },
  data () {
    return {
      initial: { x: 0, y: 0 },
      current: { x: 0, y: 0 },
      loading: false,
      allowed: true
    }
  },
  methods: {
    start () {
      this.loading = true
      this.allowed = false
      this.$emit('refresh', {
        done: () => this.stop()
      })
    },
    stop () {
      this.loading = false
    },
    touchstart (e) {
      if (typeof e.targetTouches !== 'undefined') {
        const touch = e.targetTouches[0]
        this.initial = {
          x: touch.screenX,
          y: touch.screenY
        }
      } else {
        this.initial = {
          x: e.screenX,
          y: e.screenY
        }
      }
    },
    touchmove (e) {
      if (typeof e.changedTouches !== 'undefined') {
        const touch = e.changedTouches[0]
        this.current = {
          x: touch.screenX,
          y: touch.screenY
        }
      } else {
        this.current = {
          x: e.screenX,
          y: e.screenY
        }
      }
      const changeY = this.initial.y < this.current.y ? Math.abs(this.initial.y - this.current.y) : 0
      if (window.scrollY === 0) {
        if (changeY > 100 && !this.loading && this.allowed) this.start()
      }
    },
    touchend (e) {
      this.allowed = true
    }
  },
  beforeDestroy () {
    document.removeEventListener('touchstart', this.touchstart)
    document.removeEventListener('touchmove', this.touchmove)
    document.removeEventListener('touchend', this.touchend)
  }
}
</script>

<style scoped>
  .loading-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    animation: spin 0.5s infinite forwards;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
